body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.no-data-img{
  width: 300px;
}

/* Main Header */
header {
  background-color: #1f2937; /* Dark gray */
  color: #ffffff;
  padding: 1rem 1.5rem;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo */
header img {
  height: 70px;
}

/* Main Menu */
header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

header nav ul li {
  position: relative;
  margin-left: 1.5rem;
  height: 35px;
  width: max-content;
}

header nav ul li a,
header nav ul li span {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration-line:none !important;
  padding:6px 10px;

}

header nav ul li a:hover,
header nav ul li span:hover {
  color: #233155; /* Light gray */
  text-decoration: none;
  background-color: #f9fafb
}
.submenu{
  width: max-content !important;
  z-index: 1;
}
/* Dropdown Menu */
header nav ul li .dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: calc(100% + 0.5rem);
  background-color: #ffffff;
  color: #1f2937;
  width: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 10;
}

/* Show Dropdown Menu on hover */
header nav ul li:hover .dropdown-menu {
  display: block;
}

/* Dropdown Menu Links */
.dropdown-menu a {
  display: block;
  padding: 0.75rem 1rem;
  color: #1f2937;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

header nav ul li div a:hover {
  background-color: #f3f4f6; /* Lighter gray */
  color: #111827;
}

/* Dropdown Animation */
.dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

header nav ul li:hover .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
}
/* Mobile menu toggle button */
.menu-toggle {
  display: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}
.menubar{
  flex-direction: row;
}
.menuicon{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* Responsive styles */
@media (max-width: 1024px) {
  /* Toggle button visible */
  .menu-toggle {
    display: block;
  }
  .menubar{
    flex-direction: column;
  }

  /* Hide the menu by default on mobile */
  nav ul {
    display: none;
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  /* Show the menu when toggled */
  .menu-open nav ul {
    display: flex;
  }

  /* Dropdown style for mobile */
  nav ul li .dropdown {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }

  /* Menu items stack vertically */
  nav ul li {
    border-top: 1px solid #374151; /* Tailwind's bg-gray-700 */
  }

  nav ul li a,
  nav ul li span {
    padding: 1rem;
    display: block;
  }
}

/* Overall container for the chat */
.chat-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
  height: 80vh;
  overflow-y: scroll; /* To enable scrolling if messages exceed the container height */
}

/* Container for messages to control alignment */
.messages {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between messages */
  width: 100%;
}

/* Individual message box */
.message-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
  word-wrap: break-word;
  background-color: #f0f0f0;
  position: relative;
}

/* Sent messages on the right */
.message-box.sent {
  align-self: flex-end;
  background-color: #cce5ff;
}

/* Received messages on the left */
.message-box.received {
  align-self: flex-start;
  background-color: #e2e3e5;
}

/* Message header with sender name and date */
.message-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5px;
}

.sender-name {
  font-weight: bold;
}

.message-date {
  font-style: italic;
  color: #6c757d;
}

/* Message content */
.message-content {
  font-size: 14px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}

/* Styling for the entire message container */
.message-box.sent .message-content {
  background-color: #007bff;
  color: white;
}

.message-box.received .message-content {
  background-color: #f8f9fa;
  color: #333;
}
.send-notification-container {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-items: center;
  align-items: center;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 200px;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.button-container {
 height: 35px;
  width: 120px;
  display: flex;
  background-color: #111827;
  color: #cce5ff;
  align-items: center;
  justify-content: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

/* Full-height container */
.full-page-container {
  width: 100%;
  height: 87vh; /* Full viewport height */
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Custom scrollbar styling */
.full-page-container::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.full-page-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners */
}

.full-page-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover color */
}

.full-page-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track color */
}


/* Responsive */
@media (max-width: 768px) {
  header nav ul {
    flex-direction: column;
  }
  header nav ul li {
    margin-left: 0;
  }
  .dropdown-menu {
    width: 100%;
    position: relative;
  }
}
