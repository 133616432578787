.clip-bottom {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

/* .ql-container {
  height: 200px !important;
} */

.ql-container {
  min-height: 80vh;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
}
.editors{
  height: 390px !important ;
  margin-bottom: 30px;
}
.editors .ql-container{
  min-height: 390px!important ;
  height: 390px!important ;
}